<template>
  <div class="clients">
    <v-row>
      <v-breadcrumbs :items="breadcrumbs" class="px-4">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-row class="pt-4">
      <v-col cols="3" class="d-flex">
        <widget-number-of-clients ref="widget_number_of_clients"></widget-number-of-clients>
      </v-col>
      <v-col cols="3" class="d-flex">
        <widget-monthly-acquisition ref="widget_monthly_acquisition"></widget-monthly-acquisition>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col cols="12" class="py-0">
        <v-alert
          v-model="ui.alert.enable"
          :dismissible="ui.alert.dismissible"
          :icon="ui.alert.icon"
          :type="ui.alert.type"
          class="mb-0"
        >
          {{ ui.alert.message }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-card outlined
                :loading="ui.list.loading"
                :disabled="ui.list.disabled"
                class="rounded-lg overflow-hidden">
          <template slot="progress">
            <v-progress-linear
              color="blue-grey darken-3"
              height="6"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-row
            style="border-radius: 0"
            class="blue py-6 px-sm-9">
            <v-col cols="12" class="pb-0">
              <v-toolbar-title class="title white--text pl-0">
                {{ $vuetify.lang.t('$vuetify.general.clients.list.name') }}
              </v-toolbar-title>
              <p class="white--text mb-2">{{ $vuetify.lang.t('$vuetify.general.clients.list.ui.title') }}</p>
            </v-col>
          </v-row>
          <v-card-text class="px-0 pb-0">
            <div class="px-9">
              <div class="row">
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="datatable.search"
                    :disabled="ui.list.disabled"
                    :label="$vuetify.lang.t('$vuetify.global.search')"
                    ref="datatable_search"
                  >
                    <v-icon
                      slot="prepend"
                    >
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>
                <v-col
                  class="justify-end pt-7 d-flex">
                  <!--                  <v-btn-->
                  <!--                    class="mx-2"-->
                  <!--                    icon-->
                  <!--                    dark-->
                  <!--                    small-->
                  <!--                    color="grey"-->
                  <!--                  >-->
                  <!--                    <v-icon dark>-->
                  <!--                      mdi-download-->
                  <!--                    </v-icon>-->
                  <!--                  </v-btn>-->
                </v-col>
              </div>
            </div>
            <v-data-table :headers="datatable.headers"
                          :items="datatable.items"
                          :search="datatable.search"
                          :items-per-page="datatable.items_per_page"
                          :server-items-length="datatable.total.items"
                          :options.sync="datatable.options"
                          class="datatable"
            >
              <template v-slot:item.name="{ item }">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item.type="{ item }">
                <span class="overline text--secondary" v-if="!_.isUndefined(item)">
                  {{ $vuetify.lang.t('$vuetify.general.clients._types.' + item.type) }}
                </span>
              </template>
              <template v-slot:item.sites="{ item }">
                <v-chip
                  color="blue"
                  dark
                  small
                  class="mr-1 my-1"
                  v-for="(site) in item.sites"
                  v-bind:key="site.id"
                >
                  {{ site.value }}
                </v-chip>
                <span class="px-2 text--disabled"
                      v-if="_.size(item.sites)<=0">
                    -
                  </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <span
                  v-bind:key="key"
                  v-for="(action, key) in _.toString(item.actions).split(',')">
                  <v-icon
                    tag="a"
                    v-if="action === 'edit'"
                    small
                    class="mr-2"
                    v-on:click="$router.push({name:'GeneralClientsEdit', params:{
                      id : item.id
                    }})"
                  >
                    mdi-pencil
                  </v-icon>

                  <v-dialog
                    v-model="datatable.deletes[item.id]"
                    persistent
                    v-if="action === 'delete'"
                    max-width="320"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <dialog-delete
                      :item="item"
                      :on-confirm="()=> { deleteAccount(item); datatable.deletes[item.id] = false }"
                      :on-cancel="()=>{datatable.deletes[item.id] = false}"
                    ></dialog-delete>
                  </v-dialog>

                  <v-dialog
                    v-model="datatable.see[item.id]"
                    v-if="action === 'see'"
                    max-width="890"
                  >
                    <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon></template>
                    <dialog-see :item="item" :on-dismiss="()=>{datatable.see[item.id] = false}"></dialog-see>
                  </v-dialog>
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="blue"
              fab
              large
              dark
              class="ml-2"
              :to="{name:'GeneralClientsAdd'}">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{ $vuetify.lang.t('$vuetify.global.add') }}
        </v-tooltip>
      </div>
    </v-fab-transition>
  </div>
</template>
<script>
import WidgetNumberOfClients from './widget/NumberOfClients'
import WidgetMonthlyAcquisition from './widget/MonthlyAcquisition'
import DialogDelete from './dialog/Delete'
import DialogSee from './dialog/See'

export default {
  components: {
    'widget-number-of-clients': WidgetNumberOfClients,
    'widget-monthly-acquisition': WidgetMonthlyAcquisition,
    'dialog-delete': DialogDelete,
    'dialog-see': DialogSee
  },
  data: () => ({
    ui: {
      list: {
        loading: true,
        disabled: true
      },
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    datatable: {
      search: '',
      search_interval: null,
      headers: [],
      total: {
        items: 0,
        pages: 0
      },
      options: {},
      current_page: 1,
      items_per_page: 5,
      items: [],
      see: {},
      deletes: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    async fetchAccounts () {
      this.ui.list.disabled = true
      this.ui.list.loading = true

      try {
        const response = await this.axios.get('/general/accounts/list', {
          params: {
            page: this.datatable.options.page,
            search: this.datatable.search,
            limit: this.datatable.options.itemsPerPage,
            sort: this.datatable.options.sortDesc.reduce((result, field, index) => {
              result[this.datatable.options.sortBy[index]] = field
              return result
            }, {})

          },
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        this.datatable.items = this._.map(response.data.data, (element) => {
          element.actions = ['see', 'edit', 'delete']
          return element
        })
        this.datatable.total.pages = response.data.totalPages
        this.datatable.total.items = response.data.totalItems
        this.datatable.current_page = response.data.currentPage
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.general.clients.list.notification.load'))
        this.datatable.items = []
      } finally {
        this.ui.list.disabled = false
        this.ui.list.loading = false
      }
    },
    async deleteAccount (item) {
      this.ui.list.disabled = true
      this.ui.list.loading = true

      try {
        await this.axios.get('/general/accounts/delete/' + item.id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })
        await this.fetchAccounts()
        this.$refs.widget_number_of_clients.reload()
        this.$refs.widget_monthly_acquisition.reload()

        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.general.clients.delete.notification.deleted'))
      } catch (e) {
        switch (parseInt(e.response.status)) {
          case 417 :
            this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.general.clients.delete.notification.users_exists'))
            break
          case 400 :
            this.alert('info', 'mdi-information', this.$vuetify.lang.t('$vuetify.general.clients.delete.notification.not_found'))
            break
          case 405 :
            this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.general.clients.delete.notification.fail'))
            break

          default :
            this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.general.clients.delete.notification.unknown'))
            break
        }
        this.Sentry.captureException(e)
      } finally {
        this.ui.list.disabled = false
        this.ui.list.loading = false
      }
    }
  },
  mounted () {
    this.fetchAccounts()
    // restore alerts
    if (!this._.isUndefined(this.$router.currentRoute.params.alert)) {
      this.ui.alert = this.$router.currentRoute.params.alert
    }
  },
  watch: {
    'datatable.options': function (data) {
      this.fetchAccounts()
    },
    'datatable.search': function (data) {
      if (!this._.isNull(this.datatable.search_interval)) clearTimeout(this.datatable.search_interval)

      this.datatable.search_interval = setTimeout(async () => {
        this.datatable.search_interval = null
        await this.fetchAccounts()
        this.$refs.datatable_search.focus()
      }, 500)
    }
  },
  created () {
    // Define translations
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.clients.name'),
        disabled: true,
        to: { name: 'GeneralClients' }
      }
    ]
    this.datatable.headers = [
      {
        value: 'type',
        sortable: false,
        text: this.$vuetify.lang.t('$vuetify.general.clients.list.ui.datatable.type')
      },
      {
        value: 'name',
        text: this.$vuetify.lang.t('$vuetify.general.clients.list.ui.datatable.name')
      },
      {
        value: 'sites',
        sortable: false,
        text: this.$vuetify.lang.t('$vuetify.general.clients.list.ui.datatable.sites')
      },
      {
        text: this.$vuetify.lang.t('$vuetify.global.actions'),
        align: 'right',
        value: 'actions',
        sortable: false
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep {
  .datatable {
    tr {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        th:first-child, td:first-child {
          padding-left: 36px;
        }

        th:last-child, td:last-child {
          padding-right: 36px;
        }
      }

    }
  }
}
</style>
