<template>
  <v-card class="rounded-lg overflow-hidden">
    <v-card-text class="px-0 pb-0">
      <v-row
        style="border-radius: 0">
        <v-col cols="5">
          <v-row>
            <v-col cols="12" class="blue py-6 px-12">
              <v-toolbar-title class="d-flex flex-column title white--text pl-0">
                <v-icon class="my-10" size="125">mdi-domain</v-icon>
                {{ item.name }}
              </v-toolbar-title>
              <p class="white--text mb-2">{{ $vuetify.lang.t('$vuetify.general.clients.see.ui.type') }} :
                                          {{ $vuetify.lang.t('$vuetify.general.clients._types.' + item.type) }}</p>
            </v-col>
            <v-col class="px-12 py-6 pb-12">

              <v-row class=" d-flex flex-column">
                <v-col class="p-0">
                  <p class="mb-0 font-weight-bold">
                    {{ $vuetify.lang.t('$vuetify.general.clients.see.ui.accessible_websites') }}</p>
                </v-col>
                <v-col class="pt-0 pl-0">
                  <v-chip v-for="site in item.sites"
                          :key="site.id"
                          class="ma-2"
                  >
                    {{ site.value }}
                  </v-chip>
                  <span class="px-3"
                    v-if="_.size(item.sites)<=0">
                    -
                  </span>
                </v-col>
              </v-row>

              <v-row class="pt-4 d-flex flex-column">
                <v-col class="p-0">
                  <p class="mb-0 font-weight-bold">{{ $vuetify.lang.t('$vuetify.global.details') }} : </p>
                </v-col>
                <v-col class="pt-0">
                  <p class="font-weight-light mb-0">
                    <span class="font-weight-bold text--disabled">{{
                        $vuetify.lang.t('$vuetify.global.created_at')
                                                                  }} :</span>
                    {{ moment(item.created_at).format('DD/MM/YYYY') }}
                  </p>
                  <p class="font-weight-light mb-0">
                    <span class="font-weight-bold text--disabled">{{
                        $vuetify.lang.t('$vuetify.global.updated_at')
                                                                  }} :</span>
                    {{ moment(item.updated_at).format('DD/MM/YYYY') }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="grey lighten-3 elevation-4 py-8 px-8">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-icon @click="onDismiss">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col class="mb-4"
                   v-if="(!_.isNull(item.phone) && !_.isEmpty(item.phone)) || (!_.isNull(item.email) && !_.isEmpty(item.email))">
              <div class="button text-uppercase pb-4">{{ $vuetify.lang.t('$vuetify.global.informations') }}</div>
              <v-divider></v-divider>
              <div class="information-container pt-4">
                <div class="information-item d-flex py-1" v-if="!_.isNull(item.phone) && !_.isEmpty(item.phone)">
                  <v-icon>mdi-phone</v-icon>
                  <div class="pl-4">{{ item.phone }}</div>
                </div>
                <div class="information-item d-flex py-1" v-if="!_.isNull(item.email) && !_.isEmpty(item.email)">
                  <v-icon>mdi-email</v-icon>
                  <div class="pl-4">{{ item.email }}</div>
                </div>
              </div>
            </v-col>
            <v-col class="mb-4">
              <div class="button text-uppercase pb-4">{{
                  $vuetify.lang.t('$vuetify.general.clients.see.ui.address')
                                                      }}
              </div>
              <v-divider></v-divider>
              <div class="address-container pt-4">
                <div class="address-item py-1 d-flex">
                  <v-icon>mdi-crosshairs-gps</v-icon>
                  <div class="pl-4 d-inline-block">{{ item.address }} <br> {{ item.postal_code }} {{ item.city }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    onDismiss: {
      type: Function,
      required: true
    }
  },
  data: () => ({}),
  methods: {}
}
</script>
